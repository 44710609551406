import QueryHandler from '@aurora/shared-client/components/common/QueryHandler/QueryHandler';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import type { ComponentProp } from '@aurora/shared-client/helpers/components/CustomComponentsHelper';
import { getComponentContextProps } from '@aurora/shared-client/helpers/components/CustomComponentsHelper';
import { ComponentMarkupLanguage } from '@aurora/shared-generated/types/graphql-schema-types';
import type { CachedComponent } from '@aurora/shared-types/components';
import { getLog } from '@aurora/shared-utils/log';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';
import type {
  RenderCustomComponentQuery,
  RenderCustomComponentQueryVariables
} from '../../../types/graphql-types';
import EditContext from '../../context/EditContext/EditContext';
import useCustomComponentTranslation from '../../useCustomComponentTranslation';
import renderCustomComponent from '../RenderCustomComponent.query.graphql';

const log = getLog(module);

const ErrorAlert = dynamic(() => import('./ErrorAlert'));
const HtmlContent = dynamic(() => import('./HtmlContent'));
const TemplateContent = dynamic(() => import('./TemplateContent'));

interface Props {
  /**
   * The component.
   */
  cachedComponent: CachedComponent;

  /**
   * Specifies any props to pass to the custom component.
   */
  customComponentProps?: Array<ComponentProp>;
}

const CustomComponentContent: React.FC<React.PropsWithChildren<Props>> = ({
  cachedComponent,
  customComponentProps
}) => {
  const { component, globalCss } = cachedComponent;
  const { id: customComponentId } = component;
  const language = component?.template.markupLanguage;
  const { pageTemplateContext } = useContext(AppContext);

  const queryResult = useQueryWithTracing<
    RenderCustomComponentQuery,
    RenderCustomComponentQueryVariables
  >(module, renderCustomComponent, {
    variables: {
      componentId: customComponentId,
      context: {
        page: pageTemplateContext,
        component: {
          props: getComponentContextProps(customComponentProps),
          entities: []
        }
      }
    }
  });

  const { showEditControls } = useContext(EditContext);
  const { loading, data, error: queryError } = queryResult;
  const i18n = useCustomComponentTranslation(customComponentId, component.template.texts);
  const { formatMessage } = i18n;
  const markup = data?.component?.render?.html;
  const scriptGroups = data?.componentScriptGroups;

  if (queryError) {
    log.error(
      `Unable to fetch component to render for component id ${customComponentId}: ${queryError.message}`
    );
    if (showEditControls) {
      return <ErrorAlert customComponentId={customComponentId} error={queryError} />;
    } else {
      return null;
    }
  }

  if (!loading && !data?.component) {
    log.error('Component with id: %s not found', customComponentId);
    return null;
  }

  if (loading) {
    return null;
  }

  const templateId = cachedComponent.component.template.id;
  return (
    <QueryHandler<RenderCustomComponentQuery, RenderCustomComponentQueryVariables>
      queryResult={queryResult}
    >
      {(): React.ReactNode => {
        if (queryError) {
          log.error('Unable to load custom component.', queryError);
          return null;
        }
        return language === ComponentMarkupLanguage.Html ? (
          <HtmlContent markup={markup} scriptGroups={scriptGroups} />
        ) : (
          <TemplateContent
            templateId={templateId}
            formatMessage={formatMessage}
            markup={markup}
            scriptGroups={scriptGroups}
            globalCss={globalCss}
          />
        );
      }}
    </QueryHandler>
  );
};

export default CustomComponentContent;
